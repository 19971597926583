<template>
  <div class="contact-page">
		<div class="inner-page" @scroll="pageScroll($event)">
      <div class="content-container">
        <div class="indent fixed-width mb-5">
					
          <h1><span id="contact-title" class="txtan">Contact </span><span id="contact-title-2" class="txtan light">Information</span></h1>
					<p class="txtan" id="contact-bread">Do you need help with your next project or have an investment opportunity? Please reach out to schedule a discovery call or request more information.</p>
          
          
          <div class="location">
            <img src="../assets/icons/location.svg"><span>I'm currently in  <strong :class="{loading: this.loadingGps}">{{this.gpsPosition.city}}, {{this.gpsPosition.country}}</strong><img @mouseover="popup($event,'Last updated: ' + this.gpsPosition.lastUpdated)" @mouseout="popup($event)" src="../assets/icons/question.svg"><br>The local time is  <strong :class="{loading: this.loadingGps}">{{this.localTime}}</strong></span>
          </div>

          <div class="columns">
            <div>
              <h3>Phone</h3>
              <ul>
                <li><a href="tel:+13108807313"><span>US:</span><span>+1</span> (310) 880-7313</a></li>
                <li><a href="tel:+4522268989"><span>DK:</span><span>+45</span> 22 26 89 89</a></li>
                <li><a href="tel:+4407469937234"><span>UK:</span><span>+44</span> 0 746 993 7234 </a></li>
                <li><a href="tel:+36902848195"><span>ES:</span><span>+36</span> 902 84 81 95</a></li>
              </ul>
            </div>
            <div>
              <h3>Address</h3>
              <address>
                Mickie C. Storm<br>
                404 N Palm Dr.<br>
                Beverly Hills, CA-90210<br>
                United States<br>
              </address>
            </div>
            <div>
              <h3>Connect</h3>
              <ul>
                <li><a href="https://linkedin.com/in/mickiestorm">LinkedIn</a></li>
                <li><a href="https://instagram.com/mickiestorm/">Instagram</a></li>
              </ul>
            </div>
          </div>

          <div class="hr"></div>        
          
          
          <form id="contact-form" @submit="checkForm($event)" action="/" method="post">
            
            <h3>Write a Message</h3>

            <input :disabled="messageSending" placeholder="Your email" type="text" name="email" id="email" v-model="messageFrom">
            <textarea :disabled="messageSending" placeholder="Your message" rows="5" cols="60" name="message" id="message" v-model="message"></textarea>

            <input class="btn" type="submit" :disabled="messageSending" value="Send message">  

            <span class="info-message error" :class="{show: this.errorMessage}">{{errorMessage}}</span>
            <span class="info-message success" :class="{show: this.successMessage}">{{successMessage}}</span>
            <span class="info-message status" :class="{show: this.statusMessage}">{{statusMessage}}</span>

          </form>
				</div>
      </div>
    </div>
    <div class="page-background" id="background-video">
			<video class="show fadeInAnimation" autoplay playsinline muted loop @loadeddata="videoLoaded()" poster="../assets/videos/bg3.jpg">
				<source src="../assets/videos/bg3.webm" type="video/webm">
				<source src="../assets/videos/bg3.mp4" type="video/mp4">
			</video>
		</div>
  </div>
</template>


<script>

  import axios from 'axios';

  export default {
    name: 'Contact',
    data() {
      return {
        loading: true,
        loadingGps: true,
        videoReady: false,
        messageSending: false,
        messageFrom: '',
        message: '',
        errorMessage: '',
        successMessage: '',
        statusMessage: '',
        gpsPosition: {
          city: 'loading...',
          country: 'loading...'
        },
        localTime: 'Loading data...'
      };
    },
    mounted(){
      this.$nextTick(function () {
        this.$store.commit('setLoading', false);
				this.textAnimation();

				// Get GPS Position
				axios
					.get(this.$store.state.app.apiUrl + '/gps-location')
					.then(response => {
						this.$store.commit('setLoading', false);
						this.gpsPosition = {
              country: (response.data.data) ? response.data.data.attributes.country : 'Location not found...',
              city: (response.data.data) ? response.data.data.attributes.city : 'Location not found...',
              timezone: (response.data.data) ? response.data.data.attributes.timezone : '',
              lastUpdated: (response.data.data) ? response.data.data.attributes.updatedAt : '',
            };
            this.updateTime();
            setTimeout(function(){
              this.loadingGps = false;
            }.bind(this), 1250);
				});
      });
    },
    methods: {
      checkForm(e) {
        
        e.preventDefault();
        
        if (this.validEmail(this.messageFrom) && this.message.length > 4){

           // Set state
          this.messageSending = true;
          this.errorMessage = '';
          this.statusMessage = 'Sending message...';

          // Validation passed. Send email
          const messageData = {
            "data": {
              "fromEmail": this.messageFrom,
              "message": this.message  
            }
          }

          axios.post('https://api.storm.ms/api/contact-inquiries', messageData).then(() => {
    
              this.messageSending = false
              this.successMessage = 'Message is sent! Thank you.';
              this.statusMessage = '';
              this.messageFrom = '';
              this.message = '';

              setTimeout(function(){
                this.successMessage = '';
              }.bind(this), 5000);

          }).catch((error) => {
            console.log(error);
            this.messageSending = false;
            this.statusMessage = '';
            this.errorMessage = 'Something went wrong. Please contact me at ms@storm.ms';
          });

        } else {

          this.errorMessage = 'Please enter a valid email and message longer than 5 characters...';

        }

      },
      validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      updateTime(){
        const moment = require('moment-timezone');
        const timezone = '' + this.gpsPosition.timezone; // Convert to string
        const timestamp = moment().tz(timezone).format('h:mma / HH:mm  (z)');
        this.localTime = (timestamp) ? timestamp : '';
        setTimeout(function(){
          this.updateTime();
        }.bind(this), 1000);
      },
      videoLoaded() {
				this.videoReady = true;
			},
			textAnimation() {
				

				var timeline = [
					{
						id: 'contact-title',
						start: 300,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'contact-title-2',
						start: 450,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'contact-bread',
						start: 750,
						style: 'word',
						class: 'fadein',
						segementAnimationDelay: 8,
						segementAnimationDuration: 3000,
					},
				];
				
				
				timeline.forEach(function(item) {

					if (item.style == 'character') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/.{1}/g);
						
						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == ' ') ? '&nbsp' : letters[i];

							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
						}

						elem.innerHTML = html;
					}

					if (item.style == 'word') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/\S*/g);

						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == '') ? '' : letters[i];

              if (text != ''){
                html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '&nbsp;</span>'
              }

						}

						elem.innerHTML = html;
					}

					if (item.style == 'all') {

						let txtanElems = document.getElementById(item.id);
						let text = txtanElems.textContent;

						const startDelay = item.start;
						const letterDelay = item.segementAnimationDelay;
						const animationDuration = item.segementAnimationDuration;

						const delay = startDelay + letterDelay;

						txtanElems.innerHTML = '<span class="letter ' + item.class + '" style="animation-delay:' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span> '; 
					}
					

				});
			},
			pageScroll(event){
				
				const yScrollPos = event.target.scrollTop
				
				const yScrollStart = 50;
				const yScrollDistance = 500;

				const yScroll = (yScrollPos < yScrollStart) ? 0 : (yScrollPos - yScrollStart);
				const yScrollPct = (yScroll / yScrollDistance > 1) ? 1 : (yScroll / yScrollDistance).toFixed(2);
				
				if (this.pageScrollPos != 1 - yScrollPct){
					const opacity = (1 - yScrollPct).toFixed(2);
					this.pageScrollPos = opacity;
					const videoElem = document.getElementById('background-video');
					videoElem.style.opacity = opacity;
				}

			},
      popup(event,text=''){

        if (event.type == 'mouseover'){

          const contactPage = document.getElementById('app').querySelector('.inner-page');
          let newPopup = null;

          if (document.getElementById('app').querySelector('.popup')){
            newPopup = document.getElementById('app').querySelector('.popup');
          } else {
            newPopup = document.createElement('div');
            newPopup.classList.add('popup');
            contactPage.appendChild(newPopup);
          }

          newPopup.innerHTML = text;
          const link = event.target;

          const positionX = link.offsetLeft - (newPopup.offsetWidth * 0.5) + (link.width * 0.5);
          const positionY = link.offsetTop - newPopup.offsetHeight - 5;
          newPopup.style.left = positionX + 'px';
          newPopup.style.top = positionY + 'px';
          newPopup.classList.add('visible');

        }
        if (event.type == 'mouseout'){
          const popup = document.getElementById('app').querySelector('.popup');
          popup.classList.remove('visible');
        }
      }
    }
  }
</script>


<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

  #app {

    .popup {
      z-index: 999;
      position: absolute;
      top: -100px;
      left: 0px;
      font-size: $font-size-normal * 0.9;
      letter-spacing: 0.45px;
      background-color: $gray-500;
      padding: 6px 8px;
      line-height: 13px;
      border-radius: 2px;
      transition-property: opacity;
      transition-duration: 500ms;
      opacity: 0;

      &.visible {
        transition-duration: 150ms;
        opacity: 1;
      }

    }

    .content .contact-page .content-container {

      max-width:1000px;
      
      p {
        margin-bottom: $margin-l * 1.1;
      }

    }
  }

  .contact-page {

    .location {
      background: $default-gradient;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      padding: 16px;
      margin-bottom:$margin-m;

      span {
        color:$gray-300;
    
        strong {
          font-weight:bold;
          color: $text-color;
          margin-left:3px;
          transition-property: color;
          transition-duration: 750ms;

          &.loading {
            border-radius: 1rem;
            display:inline-block;
            line-height: 1.2rem; 
            color: rgba(0,0,0,0); 
            background-image: linear-gradient(45deg, rgba(255,255,255,0.0) 1%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.0) 100%);
            animation: shine-lines 1000ms infinite linear;
          }

        }

        img {
          width: 17px;
          height: 17px;
          display: inline-block;
          margin-left: 8px;
          margin-bottom: -3px;
          opacity:0.5;
        }


      }

      img {
        margin-left: 5px;
        margin-right: 16px;
      }

    }

    .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: stretch;

      color: $gray-200;

      div {
        flex: 1 1 auto;
        
        h3 {
          margin-bottom: $margin-xs;
          color: $text-color;
          font-size: $font-size-normal;
        }


        ul {
          list-style: none;
          margin:0px;
          padding:0px;
          
          li {
          
            a {
             
              color: $gray-200;
              text-decoration: none;
              transition-property: color;
              transition-duration: 750ms;
              
              &:hover {
                color: $text-color;
                text-decoration: underline;
                transition-duration: 100ms;
              }

              span:nth-child(1) {
                width: 35px;
                display: inline-block;
              }

            }

          }

        }

        address {
          font-style:normal;
        }
        
      }

    }

    .hr {
      background-color: $gray-700;
      height:2px; 
      width: 100%;
      margin: ($margin-m*1.75) 0;
    }

    form {

      display:block;

      input, textarea {
        margin-bottom: $margin-m
      }

      input[type="submit"]{
        width: auto;
        border:none;
        padding: ($margin-base * 1.8)  ($margin-base * 3); 
        line-height: 19.0333px;
      }

      .info-message {
    
        opacity:0;
        transition-property: opacity;
        transition-duration: 300ms;

        &.show {
          opacity: 1;
          padding-left: 16px;
        }

        &.error {
          color: #ff9595;
        }

        &.success {
          color: #dbf9a6;
        }

      }
    }
    

   




  }


  @keyframes shine-lines{
    0%{
      background-position: 0px 50%;
    }
    100% {
      background-position: 200px 50%;
    }
    
  }

  

</style>